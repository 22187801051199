/* eslint-disable no-restricted-globals */
/* eslint-disable @typescript-eslint/no-shadow */
import { Combobox as HeadlessCombobox, Transition } from '@headlessui/react';
import { forwardRef, Fragment, useRef, useState } from 'react';
import clsx from 'clsx';
import { usePopper } from 'react-popper';

export type ComboboxOption = {
  /** ID of the option */
  id: string;
  /** Option name to display */
  name: string;
  /** Optional option description added to display if exists */
  description?: string | null;
};

export type ComboboxProps = {
  /** ID of the input field. Used to match with label */
  id: string;
  /** Name of the field. Used to track within formik  */
  name: string;
  /** The current value. Must match one of the options. Can be undefined */
  value?: ComboboxOption;
  /** The event which will trigger when an option is selected */
  onChange: (option: ComboboxOption) => void;
  /** The options available in the listbox. These will normally be generated on the fly as a result of the query string */
  filteredOptions: ComboboxOption[];
  /** The event which triggers when the query string is changed. This will be fed into the filter logic */
  onQueryChange: (query: string) => void;
  /** The event which will trigger when the listbox is blurred */
  onBlur?: () => void;
  /** The messagee to display when the field is empty */
  placeholder: string;
  /** The message to display when there are no options */
  noOptionsMessage: string;
  /** Error state */
  error?: boolean;
  /** Disabled state */
  disabled?: boolean;
  /** displays only the item description property */
  itemDescriptionOnly?: boolean;
};

const Combobox = forwardRef<HTMLInputElement, ComboboxProps>(
  (
    {
      id,
      name,
      filteredOptions,
      onQueryChange,
      value,
      onChange,
      onBlur,
      placeholder,
      noOptionsMessage,
      error,
      disabled,
      itemDescriptionOnly = false,
    }: ComboboxProps,
    forwardedRef
  ) => {
    const popperElRef = useRef(null);
    const [comboboxButtonlement, setComboboxButtonElement] =
      useState<HTMLDivElement | null>(null);
    const [comboboxDropdownElement, setComboboxDropdownElement] =
      useState<HTMLDivElement | null>(null);
    const {
      styles,
      attributes: { popper },
    } = usePopper(comboboxButtonlement, comboboxDropdownElement, {
      placement: 'bottom',
    });

    return (
      <HeadlessCombobox name={name} value={value} onChange={onChange} disabled={disabled}>
        {({ open }) => (
          <div ref={setComboboxButtonElement} className="relative w-full">
            <HeadlessCombobox.Input
              className={clsx(
                'justify-between bg-transparent border-2 outline-none transition-colors text-main-content-1 field-base placeholder:text-main-content-2 focus:border-main-content-1',
                { 'border-error-border': !open && error },
                { 'border-main-content-3': !open && !error },
                { 'rounded-lg ': !open },
                {
                  'remove-bottom-radius border-main-content-1':
                    open && popper?.['data-popper-placement'] === 'bottom',
                },
                {
                  'remove-top-radius border-main-content-1':
                    open && popper?.['data-popper-placement'] === 'top',
                },
                { 'opacity-40': disabled }
              )}
              placeholder={placeholder}
              displayValue={(option: ComboboxOption) => option?.name || ''}
              onChange={e => {
                onQueryChange(e.target.value);
              }}
              id={id}
              data-testid={id}
              onBlur={onBlur}
              ref={forwardedRef}
            />
            <div
              ref={popperElRef}
              className="z-10 w-full"
              style={styles.popper}
              {...popper}
            >
              <Transition
                show={open}
                as={Fragment}
                enter="transition-opacity ease-in duration-75"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                beforeEnter={() => setComboboxDropdownElement(popperElRef.current)}
                afterLeave={() => setComboboxDropdownElement(null)}
              >
                <HeadlessCombobox.Options
                  className={clsx(
                    'overflow-auto z-10 w-full max-h-72 bg-white border-2 focus:outline-none lg:max-h-80 border-main-content-1',
                    {
                      'rounded-b-lg border-t-0':
                        popper?.['data-popper-placement'] === 'bottom',
                    },
                    {
                      'rounded-t-lg border-b-0':
                        popper?.['data-popper-placement'] === 'top',
                    }
                  )}
                  onBlur={onBlur}
                  data-testid={`${id}-options`}
                  static
                >
                  {filteredOptions.length === 0 ? (
                    <div className="field-base text-popup-content-1">
                      {noOptionsMessage}
                    </div>
                  ) : (
                    filteredOptions.map(option => (
                      <HeadlessCombobox.Option
                        as={Fragment}
                        key={option.id}
                        value={option}
                      >
                        {({ active }) => (
                          <li
                            className={clsx(
                              'border-y first:border-t-0 last:border-b-0 cursor-pointer border-popup-background text-popup-content-1 field-base',
                              { 'bg-popup-background': active }
                            )}
                          >
                            <span className="block truncate">
                              {!itemDescriptionOnly && option.name}
                              {option?.description && <>{option.description}</>}
                            </span>
                          </li>
                        )}
                      </HeadlessCombobox.Option>
                    ))
                  )}
                </HeadlessCombobox.Options>
              </Transition>
            </div>
          </div>
        )}
      </HeadlessCombobox>
    );
  }
);

export default Combobox;
