import { Icon, FormattedPrice } from '@dayinsure/components';
import clsx from 'clsx';
import { useFormikContext } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsMutating } from 'react-query';
import { useLocation, useParams } from 'react-router-dom';
import { ExtendPolicyRoutes } from '../../../../helpers/forms/account/ExtendPolicyFormHelpers';
import {
  QuoteJourneyFormData,
  QuoteJourneyRoutes,
} from '../../../../helpers/forms/quote';
import QuoteEditLink from '../../../Common/QuoteEditLink/QuoteEditLink';
import QuoteSummaryDialog from '../QuoteSummaryDialog/QuoteSummaryDialog';
import { getDiscount, getDiscountValue } from '../../../../helpers/discount/discount';
import useQuoteQuery from '../../../../hooks/queries/useQuoteQuery';
import {
  getQuoteData,
  displayDataFromApi,
  removeRequestQuoteId,
} from './QuoteSummaryCard.utils';
import Documents from './Documents/Documents';

type QuoteSummarCardProps = {
  currentPage: QuoteJourneyRoutes | ExtendPolicyRoutes;
  showReturnLinks?: boolean; // Hard coded to quote journey routes
  className?: string;
};

const QuoteSummaryCard = ({
  currentPage,
  showReturnLinks = false,
  className,
}: QuoteSummarCardProps) => {
  const { values } = useFormikContext<QuoteJourneyFormData>();
  const { pathname } = useLocation();
  const quoteIdOrRequestQuoteId =
    useParams<{ quoteId: string }>().quoteId || values.quoteId;
  const quoteId = removeRequestQuoteId(pathname, quoteIdOrRequestQuoteId);
  const { data: quote } = useQuoteQuery(quoteId);
  const { t } = useTranslation('quotes');

  const {
    excess,
    coverLength,
    firstName,
    surname,
    price,
    product,
    underwriter,
    vehicleRegistration,
    vehicleModel,
  } = getQuoteData(displayDataFromApi(pathname), values, quote?.detail);

  const [breakdownOpen, setBreakdownOpen] = useState(false);
  const isUpdatingQuote = useIsMutating(['update-quote']);
  const isCreatingAmendQuote = useIsMutating(['amend-quote']);
  const isLoading = isUpdatingQuote || isCreatingAmendQuote;

  const labelContainerClass = 'mt-4 mb-2 flex justify-between';
  const labelClass = 'flex gap-2 items-center text-sm leading-3 text-white/60';

  const productName =
    product === 'ShortTermMotor'
      ? t('review.short_term_motor')
      : t('review.learner_motor');

  const discount = getDiscount(price);
  const isLearner = !!values.registeredKeeper;

  return (
    <>
      <div
        className={clsx(
          `mb-4 w-full max-w-[14rem] text-white lg:min-w-[19.5rem] lg:max-w-[19.5rem]`,
          className
        )}
      >
        <div className={clsx('p-6 rounded-t-lg bg-summary-2')}>
          <span className="block mb-4 text-lg">{t('summary.title')}</span>
          {vehicleRegistration && (
            <div className="flex justify-between mb-2">
              <span
                className="inline-flex py-1 px-2 text-sm bg-white/10 rounded"
                id="summary-reg-plate"
              >
                {vehicleRegistration}
              </span>
              <QuoteEditLink
                id="summary-edit-vehicle"
                to={`/quote/${QuoteJourneyRoutes.RegistrationSearch}`}
                show={showReturnLinks}
              />
            </div>
          )}
          {vehicleModel && (
            <span className="block" id="summary-car-details">
              {vehicleModel.name}
            </span>
          )}

          {currentPage !== QuoteJourneyRoutes.DriverDetails && firstName && surname && (
            <>
              <div className={labelContainerClass}>
                <span className={labelClass}>
                  <Icon name="user" />
                  {t('summary.driver')}
                </span>
                <QuoteEditLink
                  id="summary-edit-driver"
                  to={`/quote/${QuoteJourneyRoutes.DriverDetails}`}
                  show={showReturnLinks}
                />
              </div>
              <span id="summary-name">{`${firstName} ${surname}`}</span>
            </>
          )}

          {coverLength &&
            currentPage !== QuoteJourneyRoutes.DriverDetails &&
            currentPage !== QuoteJourneyRoutes.ContactDetails &&
            currentPage !== QuoteJourneyRoutes.LearnerDriver && (
              <>
                <div className={labelContainerClass}>
                  <span className={labelClass}>
                    <Icon name="date" />
                    {t('summary.cover_length')}
                  </span>
                  {currentPage !== QuoteJourneyRoutes.CoverLength && (
                    <QuoteEditLink
                      id="summary-edit-cover-length"
                      to={`/quote/${quoteId}/${QuoteJourneyRoutes.CoverLength}`}
                      show={showReturnLinks}
                    />
                  )}
                </div>

                <span id="summary-cover-length">
                  {`${coverLength.value} ${coverLength.type?.toLowerCase()}${
                    coverLength.value && coverLength.value > 1 ? 's' : ''
                  }${
                    product !== 'ShortTermMotor' &&
                    coverLength.value &&
                    coverLength.type?.toLowerCase() === 'month'
                      ? ` (${coverLength.value * 28} days)`
                      : ''
                  }`}{' '}
                </span>
              </>
            )}

          {product &&
            currentPage !== QuoteJourneyRoutes.DriverDetails &&
            currentPage !== QuoteJourneyRoutes.ContactDetails &&
            currentPage !== QuoteJourneyRoutes.LearnerDriver && (
              <>
                <div className={labelContainerClass}>
                  <span className={labelClass}>
                    <Icon name="policies" />
                    {t('summary.product')}
                  </span>
                </div>
                <span id="summary-product">{productName}</span>
              </>
            )}

          {underwriter &&
            currentPage !== QuoteJourneyRoutes.DriverDetails &&
            currentPage !== QuoteJourneyRoutes.ContactDetails &&
            currentPage !== QuoteJourneyRoutes.LearnerDriver && (
              <>
                <div className={labelContainerClass}>
                  <span className={labelClass}>
                    <Icon name="medal" />
                    {t('summary.insurer')}
                  </span>
                </div>
                <span id="summary-insurer">{underwriter}</span>
              </>
            )}

          {quoteId &&
            currentPage !== QuoteJourneyRoutes.DriverDetails &&
            currentPage !== QuoteJourneyRoutes.ContactDetails &&
            currentPage !== QuoteJourneyRoutes.LearnerDriver && (
              <Documents
                labelContainerClass={labelContainerClass}
                labelClass={labelClass}
                productName={productName}
                quoteId={quoteId}
              />
            )}
        </div>
        <div className="relative p-6 rounded-b-lg bg-summary-1">
          {currentPage !== QuoteJourneyRoutes.DriverDetails &&
          currentPage !== QuoteJourneyRoutes.ContactDetails &&
          currentPage !== QuoteJourneyRoutes.LearnerDriver &&
          price ? (
            <>
              <span className="flex gap-2 items-center mb-2 text-sm leading-3 text-white/60">
                <Icon name="card" />
                {t('summary.price')}
              </span>
              <div
                className={clsx('flex gap-3 items-center', {
                  'text-white/20': isLoading,
                  'text-white': !isLoading,
                })}
              >
                {discount && (
                  <span
                    className="block text-xl leading-9 opacity-60"
                    id="summary-discount-desktop"
                  >
                    <FormattedPrice
                      price={getDiscountValue(price)}
                      lineThrough
                      size="BASE"
                    />
                  </span>
                )}
                <span
                  className="block text-xl leading-9"
                  id="summary-price-details-desktop"
                >
                  <FormattedPrice price={price.total} />
                </span>
              </div>
              <span className="block mb-2 text-sm leading-4">
                {t('summary.fees')}
                {excess && (
                  <span className="pl-1">
                    ({t('summary.excess')} £{excess})
                  </span>
                )}
                .
              </span>

              <button
                aria-label={t('summary.dialog.breakdown_title')}
                type="button"
                onClick={() => {
                  setBreakdownOpen(true);
                }}
                className="flex gap-1 items-center w-full"
              >
                <Icon name="info" />
                <span className="text-sm">{t('summary.price_breakdown')}</span>
              </button>
            </>
          ) : (
            <p className="text-sm" id="summary-price-details-desktop">
              {t('summary.price_to_be_shown')}
            </p>
          )}
        </div>
      </div>
      {price && (
        <QuoteSummaryDialog
          price={price}
          open={breakdownOpen}
          isLearner={isLearner}
          underwriter={underwriter}
          excess={excess}
          onClose={() => {
            setBreakdownOpen(false);
          }}
        />
      )}
    </>
  );
};

export default QuoteSummaryCard;
